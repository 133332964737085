import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Alert,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

function ImageResize() {
  const [imageFile, setImageFile] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [unit, setUnit] = useState("px"); // 기본 단위 px
  const [resizeOption, setResizeOption] = useState("custom"); // 기본 옵션 custom
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB 제한

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("파일 크기는 2MB를 초과할 수 없습니다.");
        setImageFile(null);
        setImageDimensions({ width: 0, height: 0 });
      } else {
        setErrorMessage(null);
        setImageFile(file);

        // 이미지 크기 계산
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            setImageDimensions({ width: img.width, height: img.height });
            setWidth(img.width.toString());
            setHeight(img.height.toString());
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleResizeOptionChange = (event) => {
    const option = event.target.value;
    setResizeOption(option);

    if (option === "custom") {
      setWidth(imageDimensions.width.toString());
      setHeight(imageDimensions.height.toString());
    } else {
      // 이미지 비율에 따른 값 계산
      switch (option) {
        case "half":
          setWidth((imageDimensions.width * 0.5).toFixed(0));
          setHeight((imageDimensions.height * 0.5).toFixed(0));
          break;
        case "third":
          setWidth((imageDimensions.width * 0.33).toFixed(0));
          setHeight((imageDimensions.height * 0.33).toFixed(0));
          break;
        case "quarter":
          setWidth((imageDimensions.width * 0.25).toFixed(0));
          setHeight((imageDimensions.height * 0.25).toFixed(0));
          break;
        case "fifth":
          setWidth((imageDimensions.width * 0.2).toFixed(0));
          setHeight((imageDimensions.height * 0.2).toFixed(0));
          break;
        default:
          break;
      }
    }
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!imageFile) {
      setErrorMessage("이미지 파일을 선택해주세요.");
      return;
    }

    if (!width || !height) {
      setErrorMessage("너비와 높이를 모두 입력해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("unit", unit);

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/tool/image/resize-image`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("이미지 크기 조정 중 오류가 발생했습니다.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "resized_image.png";
      document.body.appendChild(a);
      a.click();
      a.remove();

      setImageFile(null);
      setWidth("");
      setHeight("");
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>이미지 크기 조정 - 모두의 도구</title>
        <meta
          name="description"
          content="이미지의 크기를 조정하여 다운로드할 수 있는 도구입니다."
        />
        <meta property="og:title" content="이미지 크기 조정 - 모두의 도구" />
        <meta
          property="og:description"
          content="업로드한 이미지의 크기를 원하는 대로 조정하세요."
        />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        이미지 크기 조정
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          type="file"
          fullWidth
          inputProps={{ accept: "image/*" }}
          onChange={handleFileChange}
          variant="outlined"
          required
          sx={{ mb: 2 }}
        />

        {imageDimensions.width > 0 && imageDimensions.height > 0 && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            원본 크기: {imageDimensions.width}px x {imageDimensions.height}px
          </Typography>
        )}

        <Typography variant="subtitle1" gutterBottom>
          크기 조정 옵션:
        </Typography>
        <RadioGroup
          row
          value={resizeOption}
          onChange={handleResizeOptionChange}
          sx={{ mb: 2 }}
        >
          <FormControlLabel value="half" control={<Radio />} label="1/2 (50%)" />
          <FormControlLabel value="third" control={<Radio />} label="1/3 (33%)" />
          <FormControlLabel value="quarter" control={<Radio />} label="1/4 (25%)" />
          <FormControlLabel value="fifth" control={<Radio />} label="1/5 (20%)" />
          <FormControlLabel value="custom" control={<Radio />} label="직접 입력" />
        </RadioGroup>

        {resizeOption === "custom" && (
          <>
            <TextField
              type="number"
              label={`너비 (${unit})`}
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              fullWidth
              variant="outlined"
              required
              sx={{ mb: 2 }}
            />
            <TextField
              type="number"
              label={`높이 (${unit})`}
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              fullWidth
              variant="outlined"
              required
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel id="unit-label">단위</InputLabel>
              <Select
                labelId="unit-label"
                value={unit}
                onChange={handleUnitChange}
              >
                <MenuItem value="px">px</MenuItem>
                <MenuItem value="cm">cm</MenuItem>
                <MenuItem value="in">인치</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "변환 및 다운로드"}
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
}

export default ImageResize;
