import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

const conversionTable = {
  "jpeg": ["png", "bmp", "tiff", "webp"],
  "jpg": ["png", "bmp", "tiff", "webp"],
  "png": ["jpeg", "bmp", "tiff", "webp", "gif"],
  "gif": ["png", "webp"],
  "tiff": ["jpeg", "png", "bmp", "webp"],
  "bmp": ["jpeg", "png", "tiff", "webp"],
  "webp": ["jpeg", "png", "bmp", "tiff", "gif"],
  "svg": ["png","jpeg"],
  "psd": ["png", "jpeg"],
  "heic": ["jpeg", "png", "bmp", "tiff"],
  "icns": ["png"]
};

function ImageConvertToFormat() {
  const [imageFile, setImageFile] = useState(null);
  const [outputFormat, setOutputFormat] = useState('');
  const [availableFormats, setAvailableFormats] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const MAX_FILE_SIZE = 5000 * 1024;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage('파일 크기는 5MB를 초과할 수 없습니다.');
        setImageFile(null);
        setAvailableFormats([]);
      } else {
        setErrorMessage(null);
        setImageFile(file);

        // 확장자 추출 및 변환 가능한 포맷 계산
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (conversionTable[fileExtension]) {
          setAvailableFormats(conversionTable[fileExtension]);
          setOutputFormat(conversionTable[fileExtension][0]); // 첫 번째 옵션을 기본값으로 설정
        } else {
          setErrorMessage('지원되지 않는 파일 형식입니다.');
          setAvailableFormats([]);
        }
      }
    }
  };

  const handleFormatChange = (event) => {
    setOutputFormat(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!imageFile) {
      setErrorMessage('이미지 파일을 선택해주세요.');
      return;
    }

    if (!availableFormats.includes(outputFormat)) {
      setErrorMessage('선택된 출력 형식은 지원되지 않습니다.');
      return;
    }

    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('output_format', outputFormat);

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/tool/image/convert`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('이미지 파일 변환 중 오류가 발생했습니다.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `converted_image.${outputFormat}`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      setImageFile(null);
      setAvailableFormats([]);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Helmet>
        <title>이미지 파일 변환기 - 모두의 도구</title>
        <meta name="description" content="이미지를 다양한 확장자(포맷)로 변환하여 다운로드할 수 있는 도구입니다." />
        <meta property="og:title" content="이미지 파일 변환기 - 모두의 도구" />
        <meta property="og:description" content="업로드한 이미지를 원하는 확장자(포멧)로 변환하여 다운로드하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        이미지 파일 변환기
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          type="file"
          fullWidth
          onChange={handleFileChange}
          variant="outlined"
          required
          sx={{ mb: 3 }}
        />

        {availableFormats.length > 0 && (
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="format-label">출력 형식</InputLabel>
            <Select
              labelId="format-label"
              value={outputFormat}
              onChange={handleFormatChange}
            >
              {availableFormats.map((format, index) => (
                <MenuItem key={index} value={format}>{format.toUpperCase()}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading || !availableFormats.length}>
          {loading ? <CircularProgress size={24} /> : '변환 및 다운로드'}
        </Button>
      </Box>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage}
        </Alert>
      )}

      <Typography variant="h5" sx={{ mt: 5 }}>
        변환 가능 표
      </Typography>
      <Table sx={{ mt: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>From \ To</TableCell>
            {Object.keys(conversionTable).map((format) => (
              <TableCell key={format}>{format.toUpperCase()}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(conversionTable).map(([from, toFormats]) => (
            <TableRow key={from}>
              <TableCell>{from.toUpperCase()}</TableCell>
              {Object.keys(conversionTable).map((format) => (
                <TableCell key={format}>{toFormats.includes(format) ? '✔️' : '❌'}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="body2" sx={{ mt: 3 }}>
        * jpg와 jpeg의 경우 직접 파일명에 확장자만 바꿔줘도 상호 변환 가능합니다.<br></br>
        * 5mb 미만의 파일만 변환 가능합니다. 
      </Typography>
    </Container>
  );
}

export default ImageConvertToFormat;
