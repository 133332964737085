import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText, FormControl, InputLabel, Select, MenuItem, Chip, Checkbox, FormControlLabel, ToggleButton, ToggleButtonGroup, Table, Paper, TableHead, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

function ExpenseManagementCalculator() {
  const [expenses, setExpenses] = useState([]);
  const [savingsGoal, setSavingsGoal] = useState('');
  const [result, setResult] = useState(null);
  const [region, setRegion] = useState('서울');
  const API_URL = process.env.REACT_APP_API_URL;

  const predefinedExpenses = [
    { category: '전기요금', type: 'utility', usageUnit: 'kWh' },
    { category: '수도요금', type: 'utility', usageUnit: 'm³' },
    { category: '가스요금', type: 'utility', usageUnit: 'm³' },
    { category: '식비', type: 'regular' },
    { category: '교통비', type: 'regular' },
    { category: '주거비', type: 'regular' },
    { category: '기타', type: 'regular' }
  ];

  const regions = [
    '서울', '부산', '인천', '대구', '대전',
    '광주', '울산', '세종', '경기도', '제주도'
  ];

  const formatNumberWithCommas = (value) => {
    const cleanValue = value.replace(/[^\d.]/g, '');
    if (cleanValue === '') return '';
    const [wholePart, decimalPart] = cleanValue.split('.');
    const formattedWholePart = parseInt(wholePart).toLocaleString();
    return decimalPart ? `${formattedWholePart}.${decimalPart}` : formattedWholePart;
  };

  const addExpense = (expenseType) => {
    const newExpense = predefinedExpenses.find(e => e.category === expenseType);
    if (newExpense) {
      setExpenses([...expenses, { 
        ...newExpense, 
        amount: '', 
        usage: '', 
        customCategory: expenseType === '기타' ? '' : expenseType, 
        isFixed: false,
        inputType: newExpense.type === 'utility' ? 'usage' : 'amount'
      }]);
    }
  };

  const removeExpense = (index) => {
    const newExpenses = expenses.filter((_, i) => i !== index);
    setExpenses(newExpenses);
  };

  const handleExpenseChange = (index, field, value) => {
    const newExpenses = [...expenses];
    newExpenses[index][field] = value;
    setExpenses(newExpenses);
  };

  const handleInputTypeChange = (index, newInputType) => {
    const newExpenses = [...expenses];
    newExpenses[index].inputType = newInputType;
    setExpenses(newExpenses);
  };

  const calculateExpenses = async () => {
    const requestData = {
      expenses: expenses.map(expense => ({
        category: expense.category === '기타' ? expense.customCategory : expense.category,
        amount: expense.inputType === 'amount' ? parseFloat(expense.amount.replace(/,/g, '')) : null,
        usage: expense.inputType === 'usage' ? parseFloat(expense.usage.replace(/,/g, '')) : null,
        type: expense.type,
        isFixed: expense.isFixed,
        inputType: expense.inputType
      })),
      savings_goal: parseFloat(savingsGoal.replace(/,/g, '')) / 100 || 0,
      region,
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/expenses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('생활비 관리 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>생활비 지출 관리 계산기 - 모두의 도구</title>
        <meta name="description" content="식비, 교통비, 공과금 등 생활비를 관리하고 절약 목표를 설정하여 지출 항목별 절약 권장 금액을 계산하는 계산기입니다." />
        <meta property="og:title" content="생활비 지출 관리 계산기 - 모두의 도구" />
        <meta property="og:description" content="생활비 지출 항목과 절약 목표를 입력하여 효율적인 지출 관리와 절약 금액을 손쉽게 계산해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        생활비 관리 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>지출 항목 추가</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {predefinedExpenses.map((expense) => (
            <Chip
              key={expense.category}
              label={`+ ${expense.category}`}
              onClick={() => addExpense(expense.category)}
              sx={{ cursor: 'pointer' }}
            />
          ))}
        </Box>

        {expenses.map((expense, index) => (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              {expense.category === '기타' ? (
                <TextField
                  label="항목명"
                  variant="outlined"
                  value={expense.customCategory}
                  onChange={(e) => handleExpenseChange(index, 'customCategory', e.target.value)}
                  sx={{ mr: 1, flex: 1 }}
                />
              ) : (
                <Typography sx={{ mr: 1, flex: 1 }}>{expense.category}</Typography>
              )}
              {expense.type === 'utility' && (
                <ToggleButtonGroup
                  value={expense.inputType}
                  exclusive
                  onChange={(_, newInputType) => handleInputTypeChange(index, newInputType)}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <ToggleButton value="usage">사용량</ToggleButton>
                  <ToggleButton value="amount">금액</ToggleButton>
                </ToggleButtonGroup>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={expense.isFixed}
                    onChange={(e) => handleExpenseChange(index, 'isFixed', e.target.checked)}
                  />
                }
                label="고정지출"
              />
              <Button onClick={() => removeExpense(index)} variant="outlined" color="secondary">
                삭제
              </Button>
            </Box>
            <TextField
              label={expense.inputType === 'usage' ? '사용량' : '금액'}
              variant="outlined"
              value={expense.inputType === 'usage' ? expense.usage : expense.amount}
              onChange={(e) => {
                const formattedValue = formatNumberWithCommas(e.target.value);
                handleExpenseChange(index, expense.inputType, formattedValue);
              }}
              type="text"
              fullWidth
              InputProps={{
                endAdornment: expense.inputType === 'usage' ? expense.usageUnit : '원',
              }}
            />
          </Box>
        ))}

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>지역</InputLabel>
          <Select
            value={region}
            label="지역"
            onChange={(e) => setRegion(e.target.value)}
          >
            {regions.map((r) => (
              <MenuItem key={r} value={r}>{r}</MenuItem>
            ))}
          </Select>
          <Typography variant="body2" color="textSecondary" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
            * 지역에 따라 수도요금과 가스요금이 달라집니다.
          </Typography>
        </FormControl>

        <TextField
          fullWidth
          label="절약 목표 (%)"
          variant="outlined"
          value={savingsGoal}
          onChange={(e) => setSavingsGoal(formatNumberWithCommas(e.target.value))}
          sx={{ mb: 2 }}
        />
        
        <Button variant="contained" color="primary" onClick={calculateExpenses}>
          계산하기
        </Button>
      </Box>
      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>

          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h6">전체 요약</Typography>
            <List>
              <ListItem>
                <ListItemText primary="총 지출" secondary={`${result.total_expenses.toLocaleString()} 원`} />
              </ListItem>
              <ListItem>
                <ListItemText primary="절약 목표" secondary={`${result.savings_goal_percentage} %`} />
              </ListItem>
              <ListItem>
                <ListItemText primary="절약 가능 금액" secondary={`${result.savings_target.toLocaleString()} 원`} />
              </ListItem>
            </List>
          </Box>

          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h6">항목별 상세 내역</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>항목</TableCell>
                    <TableCell align="right">지출 금액 (원)</TableCell>
                    <TableCell align="right">절약 금액 (원)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(result.savings_recommendations).map(([category, savings]) => (
                    <TableRow key={category}>
                      <TableCell>{category}</TableCell>
                      <TableCell align="right">{result.expenses[category]?.toLocaleString() || '0'} 원</TableCell>
                      <TableCell align="right">{savings.toLocaleString()} 원</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>합계</TableCell>
                    <TableCell align="right">{result.total_expenses.toLocaleString()} 원</TableCell>
                    <TableCell align="right">{result.savings_target.toLocaleString()} 원</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default ExpenseManagementCalculator;
