import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { keyframes } from '@emotion/react';

export default function Home() {
  const navigate = useNavigate();
  const handleNavigateToTool = () => {
    navigate('/tool');
  };

  // 문장 등장 애니메이션
  const fadeInUp = keyframes`
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  `;

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        py: 5,
      }}
    >
      <Helmet>
        <title>모두의 도구 - 편리한 웹 도구 모음</title>
        <meta name="description" content="모두의 도구에서 일상과 업무에 필요한 다양한 웹 도구를 무료로 이용하세요." />
        <meta property="og:title" content="모두의 도구 - 편리한 웹 도구 모음" />
        <meta property="og:description" content="언제 어디서나 사용 가능한 다양한 웹 도구를 무료로 제공하는 모두의 도구입니다." />
      </Helmet>
      <Box component="main" sx={{ py: 5 }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 'bold',
            py: 3,
            fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }, // 반응형 글자 크기
            whiteSpace: 'normal',
          }}
        >
          모두의 도구
        </Typography>

        {/* 문장을 한 줄씩 나누어 순차적 애니메이션 적용 */}
        <Box
          sx={{
            animation: `${fadeInUp} 1s ease-in-out`,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: { xs: 2, sm: 4 }, // 모바일 화면에서 패딩 조정
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, // 반응형 글자 크기
              lineHeight: 1.5,
              whiteSpace: 'normal',
              overflow: 'visible',
            }}
          >
            일상에서 또는 업무를 할 때 편한 웹 도구<br />
            모든 도구는 무료로 제공됩니다.<br />
          </Typography>
        </Box>

        {/* "도구 사용하기" 버튼 추가 */}
        <Box sx={{ py: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigateToTool}
            sx={{
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // 반응형 버튼 글자 크기
              padding: { xs: '0.5rem 1rem', sm: '0.75rem 1.5rem' }, // 버튼 크기 조정
            }}
          >
            도구 사용하기
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
