import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ToolMain from '../components/tools/ToolMain';
import NotFoundPage from '../components/common/NotFoundPage';
// 카테고리별 메인 컴포넌트
import SearchMain from '../components/tools/SearchMain';
import SmartstoreMain from '../components/tools/SmartstoreMain';
import OfficeMain from '../components/tools/OfficeMain';
import CalculatorMain from '../components/tools/CalculatorMain';
import ImageMain from '../components/tools/ImageMain';
// 계산기 세부 컴포넌트
import AcquisitionTaxCalculator from '../components/tools/calculator/AcquisitionTaxCalculator';
import CalorieCalculator from '../components/tools/calculator/CalorieCalculator';
import CarInstallmentCalculator from '../components/tools/calculator/CarInstallmentCalculator';
import CarRegistrationTaxCalculator from '../components/tools/calculator/CarRegistrationTaxCalculator';
import DebtRepaymentCalculator from '../components/tools/calculator/DebtRepaymentCalculator';
import DepositCalculator from '../components/tools/calculator/DepositCalculator';
import EducationCostCalculator from '../components/tools/calculator/EducationCostCalculator';
import ExpenseManagementCalculator from '../components/tools/calculator/ExpenseManagementCalculator';
import IncomeTaxCalculator from '../components/tools/calculator/IncomeTaxCalculator';
import InsuranceCalculator from '../components/tools/calculator/InsuranceCalculator';
import LoanCalculator from '../components/tools/calculator/LoanCalculator';
import MortgageCalculator from '../components/tools/calculator/MortgageCalculator';
import NetSalaryCalculator from '../components/tools/calculator/NetSalaryCalculator';
import SeveranceCalculator from '../components/tools/calculator/SeveranceCalculator';
import VATCalculator from '../components/tools/calculator/VATCalculator';
import YearEndTaxCalculator from '../components/tools/calculator/YearEndTaxCalculator';
import TransferTaxCalculator from '../components/tools/calculator/TransferTaxCalculator';
import InheritanceGiftTaxCalculator from '../components/tools/calculator/InheritanceGiftTaxCalculator';
import UtilityFeeCalculator from '../components/tools/calculator/UtilityFeeCalculator';

// 이미지 세부 컴포넌트
import ImageBackgroundRemove from '../components/tools/image/ImageBackgroundRemove';
import ImageConvertToFormat from '../components/tools/image/ImageConvertToFormat';
import ImageResize from '../components/tools/image/ImageResize';
// 오피스 세부 컴포넌트
import OfficeConvertHwpToDocx from '../components/tools/office/OfficeConvertHwpToDocx';
import OfficeConvertDocxToHwp from '../components/tools/office/OfficeConvertDocxToHwp';
import OfficePdfMerge from '../components/tools/office/OfficePdfMerge';
import OfficePdfSplit from '../components/tools/office/OfficePdfSplit';
import OfficeTextCompare from '../components/tools/office/OfficeTextCompare';
import ExcelTools from '../components/tools/office/ExcelTools';
// 검색 세부 컴포넌트
import Bing from '../components/tools/search/Bing';
import Google from '../components/tools/search/Google';
import Naver from '../components/tools/search/Naver';
// 스마트스토어 세부 컴포넌트
import BlogMyStore from '../components/tools/smartstore/BlogMyStore';
import ProductList from '../components/tools/smartstore/ProductList';
import RankingMyStore from '../components/tools/smartstore/RankingMyStore';
import SearchKeyword from '../components/tools/smartstore/SearchKeyword';
// AI 세부 컴포넌트

function ToolRoutes() {
  return (
    <Routes>
      {/* 메인 페이지 */}
      <Route path="/" element={<ToolMain />} />
      
      {/* 카테고리별 메인 페이지 */}
      <Route path="search" element={<SearchMain />} />
      <Route path="smartstore" element={<SmartstoreMain />} />
      <Route path="office" element={<OfficeMain />} />
      <Route path="calculator" element={<CalculatorMain />} />
      <Route path="image" element={<ImageMain />} />
      
      {/* 검색 세부 경로 */}
      <Route path="search/bing" element={<Bing />} />
      <Route path="search/google" element={<Google />} />
      <Route path="search/naver" element={<Naver />} />
      
      {/* 오피스 세부 경로 */}
      <Route path="office/pdf-merge" element={<OfficePdfMerge />} />
      <Route path="office/pdf-split" element={<OfficePdfSplit />} />
      <Route path="office/text-compare" element={<OfficeTextCompare />} />
      <Route path="office/convert-hwp-to-docx" element={<OfficeConvertHwpToDocx />} />  
      <Route path="office/convert-docx-to-hwp" element={<OfficeConvertDocxToHwp />} />      
      <Route path="office/excel" element={<ExcelTools />} /> 

      {/* 스마트스토어 세부 경로 */}
      <Route path="smartstore/blog-mystore" element={<BlogMyStore />} />
      <Route path="smartstore/product-list" element={<ProductList />} />
      <Route path="smartstore/ranking-mystore" element={<RankingMyStore />} />
      <Route path="smartstore/search-keyword" element={<SearchKeyword />} />

      {/* 이미지 세부 경로 */}
      <Route path="image/background-remove" element={<ImageBackgroundRemove />} />
      <Route path="image/convert" element={<ImageConvertToFormat />} />
      <Route path="image/resize-image" element={<ImageResize />} />

      {/* 계산기 세부 경로 */}
      <Route path="calculator/acquisition-tax" element={<AcquisitionTaxCalculator />} />
      <Route path="calculator/calories" element={<CalorieCalculator />} />
      <Route path="calculator/car-installment" element={<CarInstallmentCalculator />} />
      <Route path="calculator/car-registration-tax" element={<CarRegistrationTaxCalculator />} />
      <Route path="calculator/debt-repayment" element={<DebtRepaymentCalculator />} />
      <Route path="calculator/deposit" element={<DepositCalculator />} />
      <Route path="calculator/education-cost" element={<EducationCostCalculator />} />
      <Route path="calculator/expenses" element={<ExpenseManagementCalculator />} />
      <Route path="calculator/income-tax" element={<IncomeTaxCalculator />} />
      <Route path="calculator/insurance" element={<InsuranceCalculator />} />
      <Route path="calculator/loan" element={<LoanCalculator />} />
      <Route path="calculator/mortgage" element={<MortgageCalculator />} />
      <Route path="calculator/salary" element={<NetSalaryCalculator />} />
      <Route path="calculator/severance" element={<SeveranceCalculator />} />
      <Route path="calculator/vat" element={<VATCalculator />} />
      <Route path="calculator/year-end-tax" element={<YearEndTaxCalculator />} />
      <Route path="calculator/transfer-tax" element={<TransferTaxCalculator />} />
      <Route path="calculator/inheritance-gift-tax" element={<InheritanceGiftTaxCalculator />} />
      <Route path="calculator/utility-fee" element={<UtilityFeeCalculator />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default ToolRoutes;
