import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';
import { AuthContext } from '../../context/AuthContext';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Pagination,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';

export default function QuestionIndex() {
  // Moment.js 로케일 설정
  moment.locale('ko');

  // Context 및 상태 관리
  const { isLogin } = useContext(AuthContext);
  const [questionList, setQuestionList] = useState([]);
  const [size] = useState(10); // 페이지당 항목 수
  const [total, setTotal] = useState(0); // 총 항목 수
  const [kw, setKw] = useState(''); // 검색어 입력값
  const [page, setPage] = useState(0); // 현재 페이지
  const [keyword, setKeyword] = useState(''); // 검색 키워드
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL; // API URL 가져오기

  // 총 페이지 계산
  const totalPage = Math.ceil(total / size);

  // 질문 목록 가져오기
  const getQuestionList = useCallback(async () => {
    setIsLoading(true);
    const params = {
      page,
      size,
      keyword,
    };

    try {
      const response = await axios.get(`${API_URL}/api/question`, { params });
      const json = response.data;
      setQuestionList(json.question_list);
      setTotal(json.total);
      setKw(keyword);
    } catch (error) {
      console.error('Failed to fetch question list:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, size, keyword, API_URL]);

  // 컴포넌트 마운트 시 API 호출
  useEffect(() => {
    getQuestionList();
  }, [getQuestionList]);

  // 검색 처리
  const handleSearch = () => {
    setPage(0); // 첫 페이지로 이동
    setKeyword(kw); // 검색어 업데이트
  };

  // 페이지 변경 처리
  const handlePageChange = (_, newPage) => {
    setPage(newPage - 1);
  };

  // 질문 등록 버튼 클릭 처리
  const handleQuestionCreate = () => {
    if (isLogin) {
      navigate('/question/create');
    } else {
      navigate('/user/login');
    }
  };

  // JSX 렌더링
  return (
    <Box sx={{ padding: 3 }}>
      <Helmet>
        <title>질문게시판 - 모두의 도구</title>
        <meta
          name="description"
          content="사용자가 등록한 질문 목록을 확인하고 검색할 수 있는 페이지입니다."
        />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Button variant="contained" color="primary" onClick={handleQuestionCreate}>
          질문 등록하기
        </Button>
        <Box display="flex" alignItems="center">
          <TextField
            label="검색어"
            variant="outlined"
            size="small"
            value={kw}
            onChange={(e) => setKw(e.target.value)}
            sx={{ marginRight: 1 }}
          />
          <Button variant="outlined" onClick={handleSearch}>
            찾기
          </Button>
        </Box>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">번호</TableCell>
                <TableCell align="left" style={{ width: '50%' }}>제목</TableCell>
                <TableCell align="center">글쓴이</TableCell>
                <TableCell align="center">작성일시</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    등록된 질문이 없습니다.
                  </TableCell>
                </TableRow>
              ) : (
                questionList.map((question, i) => (
                  <TableRow key={question.id}>
                    <TableCell align="center">{total - page * size - i}</TableCell>
                    <TableCell>
                      <Link
                        to={`detail/${question.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {question.subject}
                      </Link>
                      {question.answers && question.answers.length > 0 && (
                        <Typography variant="caption" color="error" sx={{ marginLeft: 1 }}>
                          {question.answers.length}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {question.user ? question.user.username : ''}
                    </TableCell>
                    <TableCell align="center">
                      {moment(question.create_date).format('YYYY년 MM월 DD일 HH:mm')}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box display="flex" justifyContent="center" marginTop={3}>
        <Pagination
          count={totalPage}
          page={page + 1}
          onChange={handlePageChange}
          variant="outlined"
          color="primary"
        />
      </Box>
    </Box>
  );
}
