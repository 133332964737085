import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // ▼ 아이콘
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './header.css';

function Header() {
  const { isLogin, setIsLogin, setUsername, setAccessToken, userGrade } = useContext(AuthContext);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // 드롭다운 메뉴의 열림 상태를 위한 상태
  const [menuOpen, setMenuOpen] = useState(false); // 모바일에서 도구 메뉴 펼쳐짐 상태
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // 모바일 상태 체크

  const handleLogout = () => {
    setAccessToken('');
    setUsername('');
    setIsLogin(false);
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    localStorage.removeItem('user_grade');
    navigate('/');
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // 도구 메뉴 열림/닫힘 처리
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // 모바일 메뉴 열림/닫힘 처리
  const handleMobileMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const menuItems = (
    <>
      <ListItem button onClick={isMobile ? handleMobileMenuToggle : handleMenuOpen} className="custom-list-item">
        <ListItemText
          primary="도구"
        />
        {isMobile && (
          <ArrowDropDownIcon sx={{ color: 'white' }} /> // 모바일에서만 ▼ 아이콘 표시
        )}
      </ListItem>

      {/* 모바일에서만 드롭다운 메뉴 표시 */}
      {isMobile && (
        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/tool" className="custom-list-item">
              <ListItemText primary="> 모든 도구" sx={{ fontSize: '5px'}}   style={{ fontSize: '5px' }}/>
            </ListItem>
            <ListItem button component={Link} to="/tool/search" className="custom-list-item">
              <ListItemText primary="> 검색" sx={{ fontSize: '0.7rem'}} />
            </ListItem>
            <ListItem button component={Link} to="/tool/office" className="custom-list-item">
              <ListItemText primary="> 오피스" sx={{ fontSize: '0.7rem'}} />
            </ListItem>
            <ListItem button component={Link} to="/tool/calculator" className="custom-list-item">
              <ListItemText primary="> 계산기" sx={{ fontSize: '0.7rem'}} />
            </ListItem>
            <ListItem button component={Link} to="/tool/image" className="custom-list-item">
              <ListItemText primary="> 이미지 편집" sx={{ fontSize: '0.7rem'}} />
            </ListItem>
            <ListItem button component={Link} to="/tool/smartstore" className="custom-list-item">
              <ListItemText primary="> 스마트스토어" sx={{ fontSize: '0.7rem'}} />
            </ListItem>
          </List>
        </Collapse>
      )}

      {/* 데스크탑에서는 메뉴가 바로 나열됨 */}
      {!isMobile && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: { mt: 1.5 },
          }}
        >
          <MenuItem component={Link} to="/tool" onClick={handleMenuClose}>전체</MenuItem>
          <MenuItem component={Link} to="/tool/search" onClick={handleMenuClose}>검색</MenuItem>
          <MenuItem component={Link} to="/tool/office" onClick={handleMenuClose}>오피스</MenuItem>
          <MenuItem component={Link} to="/tool/calculator" onClick={handleMenuClose}>계산기</MenuItem>
          <MenuItem component={Link} to="/tool/image" onClick={handleMenuClose}>이미지 편집</MenuItem>
          <MenuItem component={Link} to="/tool/smartstore" onClick={handleMenuClose}>스마트스토어</MenuItem>
        </Menu>
      )}

      <ListItem button component={Link} to="/question" className="custom-list-item">
        <ListItemText primary="질문게시판" />
      </ListItem>
      {isLogin ? (
        <>
          <ListItem button component={Link} to="/user/mypage" className="custom-list-item">
            <ListItemText primary="마이페이지"  />
          </ListItem>
          <ListItem button className="custom-list-item" onClick={handleLogout}>
            <ListItemText primary="로그아웃"  />
          </ListItem>
          {userGrade === 'admin' && (
            <ListItem button component={Link} to="/user/list" className="custom-list-item">
              <ListItemText primary="관리 페이지"  />
            </ListItem>
          )}
          {userGrade === 'admin' && (
            <ListItem button component={Link} to="/question/customer-inquiries" className="custom-list-item">
              <ListItemText primary="고객센터 요청 목록" />
            </ListItem>
          )}
        </>
      ) : (
        <ListItem button component={Link} to="/user/login" className="custom-list-item">
          <ListItemText primary="로그인"  />
        </ListItem>
      )}
    </>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img src="https://www.everyonetools.com/logo-icon.png" alt="Logo" className="header-logo" />
            <Typography variant="h6" component={Link} to="/" className="header-title">
              모두의도구
            </Typography>
          </Box>
          {isMobile ? (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          ) : (
            <Box className="menu-items">{menuItems}</Box>
          )}
        </Toolbar>
      </AppBar>

      {/* 모바일 환경에서만 나타나는 Drawer */}
      {isMobile && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: '#273F44', // 사이드바 배경색을 어두운 회색으로 설정
              color: 'white', // 텍스트 색상을 흰색으로 설정
            },
          }}
        >
          <List>{menuItems}</List>
        </Drawer>
      )}
    </>
  );
}

export default Header;
