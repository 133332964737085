import React from 'react';
import { Box, Typography } from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SavingsIcon from '@mui/icons-material/Savings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SchoolIcon from '@mui/icons-material/School';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SplitIcon from '@mui/icons-material/CallSplit';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'; 
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PhotoIcon from '@mui/icons-material/Photo';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CropIcon from '@mui/icons-material/Crop';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TableChartIcon from '@mui/icons-material/TableChart';
import ImageIcon from '@mui/icons-material/Image';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const toolsData = [
    {
      category: '검색',
      tools: [
        {
          title: '네이버 검색',
          description: '네이버 검색 결과를 100개까지 크롤링합니다.(엑셀 파일로 다운로드 가능)',
          link: '/tool/search/naver',
          icon: (
            <svg className="bi" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="36" height="36">
              <g clipPath="url(#clip0_403_243)">
                <path d="M18 20H2C0.9 20 0 19.1 0 18V2C0 0.9 0.9 0 2 0H18C19.1 0 20 0.9 20 2V18C20 19.1 19.1 20 18 20Z" fill="#03C75A" />
                <path d="M11.35 10.25L8.50002 6.19995H6.15002V13.8H8.65002V9.74995L11.5 13.8H13.85V6.19995H11.35V10.25Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_403_243">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ),
        },
        {
          title: 'bing 검색',
          description: 'MS bing 검색 결과를 100개까지 크롤링합니다.(엑셀 파일로 다운로드 가능)',
          link: '/tool/search/bing',
          icon: (
            <svg className="bi" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="36" height="36">
              <path fill="#1e88e5" d="M40.69,35.42c-9.15,11.88-21.41,8.8-26.23,6.1c-7.35-4.11-12.5-13.68-9.44-23.25c0.9-2.82,2.27-5.23,3.98-7.23c1.67,0.13,3.65,0.13,6-0.04c14-1,18,11,17,14c-0.51,1.53-2.32,2.02-3.97,2.13c0.16-0.22,0.36-0.54,0.64-1.02c0.87-1.54,0.98-4.49-1.73-6.27c-2.61-1.7-5.43-0.65-6.88,1.28c-1.45,1.92-0.88,4.81-0.37,6.09c2.2,5.52,6.26,6.95,9.02,7.78c2.76,0.83,6.86,0.71,9.05-0.19c2.18-0.91,2.8-1.43,3.22-0.97C41.41,34.29,41.11,34.82,40.69,35.42z" />
              <path fill="#0d47a1" d="M40.732,35.42c-3.48,4.52-7.41,6.87-11.21,7.91c-0.03,0.01-0.06,0.01-0.08,0.02c-2.2,0.42-3.95,0.08-5.85-0.29c-3.09-0.6-7.35-4.01-8.38-10.18c-0.88-5.31,1.63-9.81,5.59-12.54c-0.26,0.24-0.49,0.5-0.7,0.78c-1.45,1.92-0.88,4.81-0.37,6.09c2.2,5.52,6.26,6.95,9.02,7.78c2.76,0.83,6.86,0.71,9.05-0.19c2.18-0.91,2.8-1.43,3.22-0.97C41.452,34.29,41.152,34.82,40.732,35.42z" />
              <path fill="#00e5ff" d="M26.94,4.25c0.02,0.26,0.03,0.54,0.03,0.81c0,3.78-1.75,7.14-4.48,9.32c-1.02-0.52-2.21-0.94-3.65-1.22c-4.07-0.78-10.63,1.1-13.3,5.77c-0.88,1.53-1.25,3.1-1.41,4.55c0.04-1.71,0.33-3.46,0.89-5.21C8.31,8.01,17.86,3.05,26.94,4.25z" />
              <path fill="#00e676" d="M41.4,27.89c-2.76,2.78-6.27,2.86-8.67,2.73c-2.41-0.12-3.59-0.82-4.69-1.5c-1.11-0.69-0.48-1.37-0.37-1.52c0.11-0.15,0.38-0.41,1-1.49c0.29-0.51,0.5-1.18,0.54-1.91c4.62-3.43,7.96-8.49,9.16-14.34c2.92,2.95,4.3,6.21,4.79,7.61C44.04,19.99,44.71,24.56,41.4,27.89z" />
              <path fill="#1de9b6" d="M38.37,9.85v0.01c-1.2,5.85-4.54,10.91-9.16,14.34c0.03-0.42,0-0.87-0.1-1.32c0-0.02-0.01-0.04-0.01-0.05c-0.25-1.47-0.99-3.33-2.22-4.77c-1.22-1.44-2.52-2.73-4.39-3.68c2.73-2.18,4.48-5.54,4.48-9.32c0-0.27-0.01-0.55-0.03-0.81c0.4,0.05,0.79,0.11,1.19,0.19C32.74,5.33,36.04,7.49,38.37,9.85z" />
            </svg>
          ),
        },
        {
          title: 'Google 검색',
          description: 'Google 검색 결과를 100개까지 크롤링합니다.(엑셀 파일로 다운로드 가능)',
          link: '/tool/search/google',
          icon: (
            <svg className="bi" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="36" height="36" >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.04 12.2614C23.04 11.4459 22.9668 10.6618 22.8309 9.90912H12V14.3575H18.1891C17.9225 15.795 17.1123 17.013 15.8943 17.8284V20.7139H19.6109C21.7855 18.7118 23.04 15.7637 23.04 12.2614Z" fill="#4285F4"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23.4998C15.105 23.4998 17.7081 22.47 19.6109 20.7137L15.8943 17.8282C14.8645 18.5182 13.5472 18.9259 12 18.9259C9.00474 18.9259 6.46951 16.903 5.56519 14.1848H1.72314V17.1644C3.61542 20.9228 7.50451 23.4998 12 23.4998Z" fill="#34A853"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.56523 14.1851C5.33523 13.4951 5.20455 12.758 5.20455 12.0001C5.20455 11.2421 5.33523 10.5051 5.56523 9.81506V6.83551H1.72318C0.944318 8.38801 0.5 10.1444 0.5 12.0001C0.5 13.8557 0.944318 15.6121 1.72318 17.1646L5.56523 14.1851Z" fill="#FBBC05"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.07386C13.6884 5.07386 15.2043 5.65409 16.3961 6.79364L19.6945 3.49523C17.7029 1.63955 15.0997 0.5 12 0.5C7.50451 0.5 3.61542 3.07705 1.72314 6.83545L5.56519 9.815C6.46951 7.09682 9.00474 5.07386 12 5.07386Z" fill="#EA4335"/>
          </svg>
          ),
        },
      ],
    },
    {
      category: '오피스',
      tools: [
        {
          title: '엑셀 작업',
          description: '엑셀 분할, 변환, 시트 추출 등 엑셀과 관련된 작업 도구입니다.',
          link: '/tool/office/excel',
          icon: (
            <Box position="relative" width="40px" height="40px">
              {/* 엑셀 아이콘 */}
              <InsertDriveFileIcon 
                fontSize="large" 
                style={{ position: 'absolute', top: 0, left: 0, fontSize: '40px', color: 'green' }} 
              />
              {/* X 모양 아이콘 */}
              <CloseIcon 
                fontSize="smell" 
                style={{ position: 'absolute', top: 13, left: 7, fontSize: '25px', color: 'white' }} 
              />
            </Box>
          ),
        },        
        {
          title: 'PDF 분할',
          description: 'pdf파일을 분할합니다.',
          link: '/tool/office/pdf-split',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <PictureAsPdfIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: 'red' }}
              />
              <SplitIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: 'white' }}
              />
            </Box>
          ),
        },
        {
          title: 'PDF 병합',
          description: '여러 pdf파일을 하나의 pdf파일로 병합합니다.',
          link: '/tool/office/pdf-merge',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <PictureAsPdfIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: 'red' }}
              />
              <MergeTypeIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: 'white' }}
              />
            </Box>
          ),
        },
        {
          title: 'Hwp 변환',
          description: '한글 파일(.hwp)을 word파일(.docx)로 변환합니다.',
          link: '/tool/office/convert-hwp-to-docx',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <InsertDriveFileIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, fontSize: '40px', color: '#008080' }}
              />
              <Typography
                style={{
                  position: 'absolute',
                  top: 22,
                  left: 8,
                  fontSize: '10px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                HWP
              </Typography>
            </Box>
          ),
        },
        {
          title: 'docx 변환',
          description: 'word파일(.docx)을 한글 파일(.hwp)로 변환합니다.',
          link: '/tool/office/convert-docx-to-hwp',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <InsertDriveFileIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, fontSize: '40px', color: '#1E90FF' }}
              />
              <Typography
                style={{
                  position: 'absolute',
                  top: 25,
                  left: 8,
                  fontSize: '8px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                DOCX
              </Typography>
            </Box>
          ),
        },
        {
          title: '텍스트 비교',
          description: '두개의 text를 비교해서 다른 부분을 표시해줍니다.',
          link: '/tool/office/text-compare',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <CompareArrowsIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: 'green' }}
              />
              <Typography
                style={{
                  position: 'absolute',
                  top: 25,
                  left: 8,
                  fontSize: '14px',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                TEXT
              </Typography>
            </Box>
          ),
        }
      ],
    },
    {
      category: '계산기',
      tools: [
        {
          title: '부동산 취득세 계산기',
          description: '부동산 취득 시 주거용과 상업용에 따라 취득세를 계산해줍니다.',
          link: '/tool/calculator/acquisition-tax',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <HomeIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#3F51B5' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '칼로리 계산기',
          description: '체중 증감 목표에 맞는 칼로리 섭취량을 계산하여 알려줍니다.',
          link: '/tool/calculator/calories',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <FastfoodIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#E91E63' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '자동차 할부 이자 계산기',
          description: '자동차 할부 구매 시 월 상환금과 총 상환액을 계산합니다.',
          link: '/tool/calculator/car-installment',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <DirectionsCarIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#FF5722' }}
              />
              <AttachMoneyIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#8BC34A' }}
              />
            </Box>
          ),
        },
        {
          title: '자동차 취등록세(취득세 및 등록세) 계산기',
          description: '목적, 종류, 가격에 따라 차량의 취득세와 등록세를 계산해줍니다.',
          link: '/tool/calculator/car-registration-tax',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <DirectionsCarIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#4CAF50' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '부채 상환 계획 계산기',
          description: '다양한 부채를 관리하며 월별 상환 계획을 세울 수 있습니다.',
          link: '/tool/calculator/debt-repayment',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <LocalAtmIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#009688' }}
              />
              <AttachMoneyIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#8BC34A' }}
              />
            </Box>
          ),
        },
        {
          title: '적금/예금 이자 및 목표 금액 계산기',
          description: '목표 금액에 필요한 적금/예금 이자 및 기간을 계산합니다.',
          link: '/tool/calculator/deposit',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <SavingsIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#FFEB3B' }}
              />
              <AttachMoneyIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#8BC34A' }}
              />
            </Box>
          ),
        },
        {
          title: '세후 연봉 계산기',
          description: '연 소득에서 세금 공제를 반영한 세후 연봉을 계산합니다.',
          link: '/tool/calculator/salary',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <AttachMoneyIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#4CAF50' }}
              />
              <ReceiptLongIcon
                fontSize="small"
                style={{ position: 'absolute', top: 15, left: 15, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '교육비 계산기',
          description: '자녀의 교육 비용을 예측하고 월별 예산을 계산합니다.',
          link: '/tool/calculator/education-cost',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <SchoolIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#2196F3' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '생활비 관리 계산기',
          description: '월별 또는 연간 생활비를 카테고리별로 입력하여 지출 관리와 절약을 돕습니다.',
          link: '/tool/calculator/expenses',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <AttachMoneyIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#FF9800' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '소득세 계산기',
          description: '개인 또는 가구의 연 소득에 따른 소득세를 계산해줍니다.',
          link: '/tool/calculator/income-tax',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <AttachMoneyIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#4CAF50' }}
              />
              <ReceiptLongIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '보험료 계산기',
          description: '생명보험, 건강보험 등의 보험료를 예측하고 수준을 산출합니다.',
          link: '/tool/calculator/insurance',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <FavoriteIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#F44336' }}
              />
              <AttachMoneyIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#8BC34A' }}
              />
            </Box>
          ),
        },
        {
          title: '대출 이자 계산기',
          description: '대출금의 월 상환액과 총 상환액을 계산해 상환 계획을 세웁니다.',
          link: '/tool/calculator/loan',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <AttachMoneyIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#009688' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '주택 담보 대출 한도 계산기',
          description: '주택 담보로 가능한 대출 한도와 예상 상환액을 제공합니다.',
          link: '/tool/calculator/mortgage',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <HomeIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#3F51B5' }}
              />
              <AttachMoneyIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#8BC34A' }}
              />
            </Box>
          ),
        },
        {
          title: '퇴직금 계산기',
          description: '근속 연수와 연봉을 기준으로 퇴직금을 계산합니다.',
          link: '/tool/calculator/severance',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <WorkOutlineIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#607D8B' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '부가가치세 계산기',
          description: '부가가치세 포함 및 제외된 가격을 계산해줍니다.',
          link: '/tool/calculator/vat',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <ReceiptLongIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#FF9800' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '연말정산 계산기',
          description: '세액공제 항목을 반영하여 예상 세금 환급액을 계산합니다.',
          link: '/tool/calculator/year-end-tax',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <ReceiptLongIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#4CAF50' }}
              />
              <CalculateIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FFC107' }}
              />
            </Box>
          ),
        },
        {
          title: '양도세 계산기',
          description: '양도 종류, 보유기간, 양도금액등을 통해서 예상 양도세를 계산합니다.',
          link: '/tool/calculator/transfer-tax',
          isNew: true,
          icon: (
            <Box position="relative" width="40px" height="40px">
              {/* 기본 아이콘 */}
              <InsertDriveFileIcon 
                fontSize="large" 
                style={{ position: 'absolute', top: 0, left: 0, color: '#4CAF50' }} 
              />
              {/* 계산 관련 아이콘 */}
              <CalculateIcon 
                fontSize="medium" 
                style={{ position: 'absolute', top: 20, left: 20, color: '#FF9800' }} 
              />
            </Box>
          ),
        },
        {
          title: '상속/증여세 계산기',
          description: '상속 재산 가치 또는 증여 금액을 입력하여 예상 세금을 계산합니다.',
          link: '/tool/calculator/inheritance-gift-tax',
          isNew: true,
          icon: (
            <Box position="relative" width="40px" height="40px">
              {/* 기본 아이콘 (법률/재정 관련) */}
              <AccountBalanceIcon 
                fontSize="large" 
                style={{ position: 'absolute', top: 0, left: 0, color: '#3F51B5' }} 
              />
              {/* 돈/자산 관련 아이콘 */}
              <MonetizationOnIcon 
                fontSize="medium" 
                style={{ position: 'absolute', top: 20, left: 20, color: '#4CAF50' }} 
              />
            </Box>
          ),
        },
        {
          title: '공공 요금 계산기',
          description: '전기, 수도, 가스 사용량에 따라 요금을 계산합니다. 지역 및 사용량을 입력하여 예상 요금을 확인하세요.',
          link: '/tool/calculator/utility-fee',
          isNew: true,
          icon: (
            <Box position="relative" width="40px" height="40px">
              <ElectricBoltIcon fontSize="medium" style={{ position: 'absolute', top: 0, left: 0, color: '#FFC107' }} />
              <WaterDropIcon fontSize="medium" style={{ position: 'absolute', top: 0, left: 20, color: '#03A9F4' }} />
              <LocalGasStationIcon fontSize="medium" style={{ position: 'absolute', top: 20, left: 10, color: '#8BC34A' }} />
            </Box>
          ),
        }
      ],
    },
    {
      category: '이미지',
      tools: [
        {
          title: '이미지 파일 변환',
          description: '이미지 파일의 확장자(포멧)를 변환합니다. png, jpg(jpeg), gif, bmp, tiff, svg, webp, heic 형식등으로 상호 변환합니다.',
          link: '/tool/image/convert',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <ImageIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, fontSize: '40px', color: '#4CAF50' }}
              />
              <SwapHorizIcon
                fontSize="large"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FF5722' }}
              />
            </Box>
          ),
        },          
        {
          title: '이미지 배경 제거',
          description: '이미지에 있는 흰색 배경을 제거하고 투명하게 만듭니다. 크기가 5MB 이하의 파일만 가능합니다.',
          link: '/tool/image/background-remove',
          icon: (
            <Box position="relative" width="40px" height="40px">
              <PhotoIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#3F51B5' }}
              />
              <BackspaceIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FF5722' }}
              />
            </Box>
          ),
        },
        {
          title: '이미지 크기 조정',
          description: '업로드한 이미지의 크기를 원하는 대로 조정하고 다운로드합니다. 크기가 1MB 이하의 파일만 가능합니다.',
          link: '/tool/image/resize-image',
          isNew: true,
          icon: (
            <Box position="relative" width="40px" height="40px">
              <PhotoIcon
                fontSize="large"
                style={{ position: 'absolute', top: 0, left: 0, color: '#3F51B5' }}
              />
              <CropIcon
                fontSize="medium"
                style={{ position: 'absolute', top: 20, left: 20, color: '#FF9800' }}
              />
            </Box>
          ),
        },
      ],
    },
    {
      category: '스마트스토어',
      tools: [
        {
          title: '내 제품 순위',
          description: '키워드로 검색하여 내 스토어의 제품 순위를 알려줍니다. 1000위까지 검색됩니다.',
          link: '/tool/smartstore/ranking-mystore',
          icon: (
            <SearchIcon fontSize="large" style={{ color: '#4CAF50' }} />
          ),
        },
        {
          title: '블로그 리뷰 검색',
          description: '내 스토어의 제품이 포함된 블로그 글을 검색합니다. 100개까지 검색됩니다.',
          link: '/tool/smartstore/blog-mystore',
          icon: (
            <ChatBubbleOutlineIcon fontSize="large" style={{ color: '#2196F3' }} />
          ),
        },
        {
          title: '키워드로 제품 검색',
          description: '특정 키워드로 검색하여 상위 노출부터 100개까지 보여줍니다. 엑셀로 다운로드 가능합니다.',
          link: '/tool/smartstore/search-keyword',
          icon: (
            <FormatListNumberedIcon fontSize="large" style={{ color: '#FFC107' }} />
          ),
        },
        {
          title: '제품 리스트',
          description: '스토어에 판매되고 있는 제품리스트를 순서대로 알려줍니다. 100개까지 검색됩니다.',
          link: '/tool/smartstore/product-list',
          icon: (
            <TableChartIcon fontSize="large" style={{ color: '#FF5722' }} />
          ),
        },
        
      ],
    }
  ];

  export default toolsData;