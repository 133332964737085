import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UtilityFeeCalculator = () => {
  const [electricityUsage, setElectricityUsage] = useState('');
  const [waterUsage, setWaterUsage] = useState('');
  const [gasUsage, setGasUsage] = useState('');
  const [region, setRegion] = useState('서울');
  const [electricityFee, setElectricityFee] = useState(null);
  const [waterFee, setWaterFee] = useState(null);
  const [gasFee, setGasFee] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const regions = [
    '서울', '부산', '인천', '대구', '대전', '광주', '울산', '세종', '경기도', '제주도'
  ];

  const calculateFee = async (usage, feeType, setFee, region = null) => {
    try {
      const payload = { usage: parseFloat(usage), fee_type: feeType };
      if (region) {
        payload.region = region;
      }
      const response = await axios.post(`${API_URL}/api/tool/calculator/utility-fee`, payload);
      setFee(response.data.fee);
    } catch (error) {
      alert('요금 계산 중 오류가 발생했습니다.');
    }
  };

  const formatNumber = (value) => {
    return value.toLocaleString();
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        공공요금 계산기
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>전기요금 계산</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="전기사용량 (kWh)"
            fullWidth
            value={electricityUsage}
            onChange={(e) => setElectricityUsage(e.target.value)}
            margin="normal"
          />
          <Button
            variant="contained"
            onClick={() => calculateFee(electricityUsage, 'electricity', setElectricityFee)}
            sx={{ mt: 2 }}
          >
            전기요금 계산
          </Button>
          {electricityFee !== null && (
            <Box mt={2}>
              <Typography>
                예상 전기요금: {formatNumber(electricityFee)} 원
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>수도요금 계산</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="수도사용량 (톤)"
            fullWidth
            value={waterUsage}
            onChange={(e) => setWaterUsage(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>지역</InputLabel>
            <Select value={region} onChange={(e) => setRegion(e.target.value)}>
              {regions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => calculateFee(waterUsage, 'water', setWaterFee, region)}
            sx={{ mt: 2 }}
          >
            수도요금 계산
          </Button>
          {waterFee !== null && (
            <Box mt={2}>
              <Typography>
                예상 수도요금: {formatNumber(waterFee)} 원
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>가스요금 계산</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="가스사용량 (m³)"
            fullWidth
            value={gasUsage}
            onChange={(e) => setGasUsage(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>지역</InputLabel>
            <Select value={region} onChange={(e) => setRegion(e.target.value)}>
              {regions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => calculateFee(gasUsage, 'gas', setGasFee, region)}
            sx={{ mt: 2 }}
          >
            가스요금 계산
          </Button>
          {gasFee !== null && (
            <Box mt={2}>
              <Typography>
                예상 가스요금: {formatNumber(gasFee)} 원
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default UtilityFeeCalculator;
